import { QUESTION_TYPE } from "enums";
import { Survey } from "interfaces";
import { Question } from "types";
import { GRAPH_TYPE } from "./DataVisualizer/DataVisualizer.types";

const convertResponses = (fireAnswers: any, survey: Survey) => {
  let conAnswers: { answers: any; meta: any; mapMarkers: any }[] = [];

  const mappedQuestions = mapSurveyQuestions(survey);

  fireAnswers.forEach((fAnswer: any) => {
    const { answers, mapMarkers, ...rest } = fAnswer;

    const newAnswers: any = [];
    Object.entries(answers).forEach(([questionId, answer]) => {
      if (
        typeof answer === "object" &&
        !Array.isArray(answer) &&
        answer !== null
      ) {
        Object.entries(answer).forEach(([marker, markerAns]) => {
          Object.entries(markerAns).forEach(([subQuestionId, subAnswer]) => {
            if (!(subQuestionId in mappedQuestions)) {
              throw new Error(`Question id not found: ${subQuestionId}`);
            }

            const question = mappedQuestions[subQuestionId];

            const existingAnswer = newAnswers.find(
              (a: any) => a.question.id === question.id
            );
            if (existingAnswer) {
              const ans = subAnswer as any;
              existingAnswer.answer = [...existingAnswer.answer, ...ans];
            } else {
              newAnswers.push({
                question,
                answer: subAnswer
              });
            }
          });
        });
      } else {
        if (!(questionId in mappedQuestions)) {
          throw new Error(`Question id not found: ${questionId}`);
        }

        const question = mappedQuestions[questionId];

        newAnswers.push({
          question,
          answer
        });
      }
    });

    conAnswers.push({
      answers: newAnswers,
      meta: rest,
      mapMarkers
    });
  });

  return conAnswers;
};

const convertFireAnswersToExport = (surveyResponses: any[]) => {
  /** Generate unique set of questions and their name */
  const uniqueQuestions = new Map<string, string>();
  surveyResponses.forEach((surveyResponse) => {
    const { answers } = surveyResponse;
    answers.forEach((answer: any) => {
      const { id, name } = answer.question;
      if (!uniqueQuestions.has(id)) {
        uniqueQuestions.set(id, name);
      }
    });
  });

  /** Generate response map for each survey response*/
  const responseMaps: Map<string, string>[] = [];

  surveyResponses.forEach((surveyResponse) => {
    const { answers } = surveyResponse;
    const responseMap = new Map<string, string>();

    answers.forEach(({ question, answer }: any) => {
      const parsedAnswer = parseAnswer(question, answer);
      responseMap.set(question.id, parsedAnswer);
    });
    responseMaps.push(responseMap);
  });

  /** Generate csv export.
   * 1. Generate headers
   * 2. Generate body: for each response map, generate a row with the answers
   */
  const headers = Array.from(uniqueQuestions.values());
  const questions = Array.from(uniqueQuestions.keys());
  const csvBody = responseMaps.map((responseMap) => {
    const row = questions
      .map((questionId) => responseMap.get(questionId) || "")
      .join(",");
    return row;
  });

  return [headers, ...csvBody];
};

const parseAnswer = (question: any, answer: any) => {
  const { type } = question;

  let parsedAnswer = "";

  if (type === QUESTION_TYPE.RADIO) {
    parsedAnswer =
      question.options.find((option: any) => option.id === answer).label || "";
  } else if (type === QUESTION_TYPE.RADIO_CONDITION) {
    // TODO: Implement
    const condition = question.conditions.find(
      (condition: any) => condition.id === answer
    );
    parsedAnswer = condition ? condition.label : "";
  } else if (type === QUESTION_TYPE.RADIO_MAP) {
    const option = question.options.find((option: any) => option.id === answer);
    parsedAnswer = option ? option.label : "";
  } else if (type === QUESTION_TYPE.RADIO_MULTI) {
    const options = question.options.filter((option: any) =>
      answer.includes(option.id)
    );
    parsedAnswer = options.map((option: any) => option.label).join(", ");
  } else if (type === QUESTION_TYPE.INPUT) {
    parsedAnswer = answer;
  } else if (type === QUESTION_TYPE.TEXT) {
    parsedAnswer = answer;
  } else if (type === QUESTION_TYPE.CHECKBOX) {
    const options = question.options.filter((option: any) =>
      answer.includes(option.id)
    );
    parsedAnswer = options.map((option: any) => option.label).join(", ");
  } else if (type === QUESTION_TYPE.MAP_MARKER) {
    // TODO: Implement
  } else if (type === QUESTION_TYPE.DROPDOWN) {
    // TODO: Check this one
    const option = question.options.find((option: any) => option.id === answer);
    parsedAnswer = option ? option.label : "";
  } else if (type === QUESTION_TYPE.CHECKBOX) {
    const options = question.options.filter((option: any) =>
      answer.includes(option.id)
    );
    parsedAnswer = options.map((option: any) => option.label).join(", ");
  } else if (type === QUESTION_TYPE.MAP_LINE) {
    // TODO: Implement
  }

  return parsedAnswer;
};

const mapSurveyQuestions = (survey: Survey) => {
  const mappedQuestions: { [key: string]: Question } = {};

  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestions(survey);

  questions.forEach((question) => {
    const { id } = question;

    if (id in mappedQuestions) {
      throw new Error(`Duplicate question id: ${id}`);
    }

    mappedQuestions[id] = question;
  });

  return mappedQuestions;
};

export const extractAllSurveyQuestions = (
  survey: Survey,
  excludeTypes?: string[]
) => {
  const { pages } = survey;

  /** Extract all page questions in single array */
  const questions: (Question & { pageIdx: number })[] = [];
  const excludeQuestionTypes = excludeTypes || [];

  pages.forEach((page, pageIdx) => {
    page.questions.forEach((question) => {
      if (question.type === QUESTION_TYPE.MAP_MARKER) {
        if (question.questions) {
          question.questions.forEach((q) => {
            if (excludeQuestionTypes.includes(q.type)) return;
            const existingQuestion = questions.find(
              (existingQ) => existingQ.id === q.id
            );
            if (!existingQuestion) {
              questions.push({ ...q, pageIdx });
            }
          });
        }

        const existingQuestion = questions.find(
          (existingQ) => existingQ.id === question.id
        );
        if (!existingQuestion) {
          questions.push({ ...question, pageIdx });
        }
      } else {
        if (!excludeQuestionTypes.includes(question.type)) {
          const existingQuestion = questions.find(
            (existingQ) => existingQ.id === question.id
          );
          if (!existingQuestion) {
            questions.push({ ...question, pageIdx });
          }
        }
      }
    });
  });
  return questions;
};

const mapQuestionsToCsvMap = (survey: Survey, splitOptions?: boolean) => {
  const mappedQuestions: Map<string, string> = new Map();

  /** Add initial keys to the mapping */
  mappedQuestions.set("respondentId", "respondentId");
  mappedQuestions.set("responseTimestamp", "responseTimestamp");

  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestions(survey);
  /** Iterate over all questions (skip *map questions) */
  for (let q = 0; q < questions.length; q++) {
    const question = questions[q];

    const { id, type, name } = question;

    /** Important early check -> If this happens, make sure ids are unique in survey questions */
    if (mappedQuestions.has(id)) {
      console.error(`mapQuestionsToCsvFormat: duplicate question id: ${id}`);
      break;
    }

    /** Conditionally map the question types */
    if (type === QUESTION_TYPE.INPUT) {
      mappedQuestions.set(id, question.name);
    } else if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN
    ) {
      const { options } = question;

      if (splitOptions) {
        options.forEach((option) => {
          const mappedId = `${id}___${option.id}`;
          const mappedLabel = `${name}___${option.label}`;
          mappedQuestions.set(mappedId, mappedLabel);
        });
      } else {
        mappedQuestions.set(id, name);
      }
    } else if (type === QUESTION_TYPE.RADIO_MULTI) {
      const { options } = question;

      options.forEach((option) => {
        const mappedId = `${id}___${option.id}`;
        const mappedLabel = `${name}___${option.label}`;
        mappedQuestions.set(mappedId, mappedLabel);
      });

      // if (splitOptions) {
      //   options.forEach((option) => {
      //     const mappedId = `${id}___${option.id}`;
      //     const mappedLabel = `${name}___${option.label}`;
      //     mappedQuestions.set(mappedId, mappedLabel);
      //   });
      // } else {
      //   mappedQuestions.set(id, name);
      // }
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      const { conditionId, conditions } = question;
      const conditionQuestion = questions.find((q) => q.id === conditionId);

      if (!conditionQuestion) {
        console.error(
          `mapQuestionsToCsvFormat: no conditionQuestion for ${conditionId}`
        );
        break;
      }

      if (!("options" in conditionQuestion)) {
        console.error(
          `mapQuestionsToCsvFormat: no options in conditionQuestion`
        );
        break;
      }

      if (
        (splitOptions && conditionQuestion.type === QUESTION_TYPE.RADIO) ||
        conditionQuestion.type === QUESTION_TYPE.DROPDOWN
      ) {
        for (let c = 0; c < conditions.length; c++) {
          const condition = conditions[c];

          const { options, id: cId } = condition;

          const optionAnswer = conditionQuestion.options.find(
            (o: any) => o.id === cId
          );

          if (!optionAnswer) {
            console.error(
              `mapQuestionsToCsvFormat: no optionAnswer in conditionQuestion.options`
            );
            break;
          }

          for (let o = 0; o < options.length; o++) {
            const option = options[o];
            const mappedId = `${conditionQuestion.id}___${cId}___${id}___${option.id}`;
            const mappedLabel = `${conditionQuestion.name}___${optionAnswer.label}___${name}___${option.label}`;
            mappedQuestions.set(mappedId, mappedLabel);
          }
        }
      } else {
        mappedQuestions.set(question.id, question.name);
      }
    }
  }

  return mappedQuestions;
};

const getPossibleDataVisualizers = (survey: Survey) => {
  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestions(survey);
  return getDataVisualizersFromQuestions(questions);
};
export const getPossibleDataVisualizersV2 = (survey: Survey) => {
  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestionsV2(survey);
  return getDataVisualizersFromQuestions(questions);
};
const getPossibleDataVisualizersMap = (survey: Survey) => {
  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestions(survey);
  return getDataVisualizersMapFromQuestions(questions);
};
export const getPossibleDataVisualizersMapV2 = (survey: Survey) => {
  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestionsV2(survey);
  return getDataVisualizersMapFromQuestions(questions);
};

const getDataVisualizersFromQuestions = (questions: Question[]) => {
  const dataVisualizers: {
    questionId: string;
    questionLabel: string;
    visualisationTypes: GRAPH_TYPE[];
    parentLabel?: string;
  }[] = [];

  /** Iterate over all questions (skip *map questions) */
  for (let q = 0; q < questions.length; q++) {
    const question = questions[q];

    const { id, type, name } = question;

    /** Conditionally map the question types */
    if (type === QUESTION_TYPE.INPUT) {
    } else if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN ||
      type === QUESTION_TYPE.RADIO_MULTI
    ) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
        visualisationTypes: GRAPH_TYPE[];
      } = {
        questionId: id,
        questionLabel: name,
        visualisationTypes: [GRAPH_TYPE.BAR, GRAPH_TYPE.PIE]
      };
      dataVisualizers.push(dataVisualizer);
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
        visualisationTypes: GRAPH_TYPE[];
      } = {
        questionId: id,
        questionLabel: name,
        visualisationTypes: [GRAPH_TYPE.BAR, GRAPH_TYPE.PIE]
      };
      dataVisualizers.push(dataVisualizer);
    } else if (type === QUESTION_TYPE.MAP_MARKER) {
      if (question.questions) {
        const subQuestions = question.questions;
        const subDataVisualizers =
          getDataVisualizersFromQuestions(subQuestions);
        subDataVisualizers.forEach((subDataVisualizer) => {
          const existingDataVisualizer = dataVisualizers.find(
            (dv) => dv.questionId === subDataVisualizer.questionId
          );
          if (!existingDataVisualizer) {
            dataVisualizers.push({ ...subDataVisualizer, parentLabel: name });
          }
        });
      }
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
        visualisationTypes: GRAPH_TYPE[];
      } = {
        questionId: id,
        questionLabel: name,
        visualisationTypes: [GRAPH_TYPE.BAR, GRAPH_TYPE.PIE]
      };
      dataVisualizers.push(dataVisualizer);
    } else {
    }
  }

  return dataVisualizers;
};

const getDataVisualizersMapFromQuestions = (questions: Question[]) => {
  const dataVisualizersMap: Map<
    string,
    {
      questionId: string;
      questionLabel: string;
      visualisationTypes: GRAPH_TYPE[];
    }[]
  > = new Map();
  dataVisualizersMap.set("Icke Kartfrågor", []);

  /** Iterate over all questions (skip *map questions) */
  for (let q = 0; q < questions.length; q++) {
    const question = questions[q];

    const { id, type, name } = question;

    /** Conditionally map the question types */
    if (type === QUESTION_TYPE.INPUT) {
    } else if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN ||
      type === QUESTION_TYPE.RADIO_MULTI
    ) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
        visualisationTypes: GRAPH_TYPE[];
      } = {
        questionId: id,
        questionLabel: name,
        visualisationTypes: [GRAPH_TYPE.BAR, GRAPH_TYPE.PIE]
      };
      const existingDataVisualizers = dataVisualizersMap.get("Icke Kartfrågor");
      if (existingDataVisualizers) {
        dataVisualizersMap.set("Icke Kartfrågor", [
          ...existingDataVisualizers,
          dataVisualizer
        ]);
      }
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
        visualisationTypes: GRAPH_TYPE[];
      } = {
        questionId: id,
        questionLabel: name,
        visualisationTypes: [GRAPH_TYPE.BAR, GRAPH_TYPE.PIE]
      };
      const existingDataVisualizers = dataVisualizersMap.get("Icke Kartfrågor");
      if (existingDataVisualizers) {
        dataVisualizersMap.set("Icke Kartfrågor", [
          ...existingDataVisualizers,
          dataVisualizer
        ]);
      }
    } else if (type === QUESTION_TYPE.MAP_MARKER) {
      if (question.questions) {
        const subQuestions = question.questions;
        const subDataVisualizers =
          getDataVisualizersFromQuestions(subQuestions);
        dataVisualizersMap.set(name, subDataVisualizers);
      }
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
        visualisationTypes: GRAPH_TYPE[];
      } = {
        questionId: id,
        questionLabel: name,
        visualisationTypes: [GRAPH_TYPE.BAR, GRAPH_TYPE.PIE]
      };
      const existingDataVisualizers = dataVisualizersMap.get("Icke Kartfrågor");
      if (existingDataVisualizers) {
        dataVisualizersMap.set("Icke Kartfrågor", [
          ...existingDataVisualizers,
          dataVisualizer
        ]);
      }
    } else {
    }
  }

  return dataVisualizersMap;
};

// const getPossibleMapVisualizers = (
//   survey: Survey,
//   surveyRespones: { answers: any; meta: any; mapMarkers: any }[]
// ) => {
//   const { pages } = survey;

//   const mapVisualizers: {
//     questionId: string;
//     questionLabel: string;
//     data: any[];
//   }[] = [];

//   /** Extract all page questions in single array */
//   const questions = pages
//     .map((page, pageIdx) =>
//       page.questions.map((question) => ({ ...question, pageIdx }))
//     )
//     .flat();

//   /** Iterate over all questions (skip *map questions) */
//   for (let q = 0; q < questions.length; q++) {
//     const question = questions[q];

//     const { id, type, name } = question;

//     /** Conditionally map the question types */
//     if (type === QUESTION_TYPE.MAP_MARKER) {
//       const dataVisualizer: {
//         questionId: string;
//         questionLabel: string;
//         data: any;
//       } = {
//         questionId: id,
//         questionLabel: name,
//         data: mapMarkersToGeoJson(
//           surveyRespones.map((respondent) => respondent.mapMarkers).flat()
//         ),
//       };
//       mapVisualizers.push(dataVisualizer);
//     }
//   }

//   return mapVisualizers;
// };

const getPossibleMapVisualizers = (survey: Survey) => {
  const mapVisualizers: {
    questionId: string;
    questionLabel: string;
  }[] = [];

  /** Extract all page questions in single array */
  const questions = extractAllSurveyQuestions(survey);

  /** Iterate over all questions (skip *map questions) */
  for (let q = 0; q < questions.length; q++) {
    const question = questions[q];

    const { id, type, name } = question;

    /** Conditionally map the question types */
    if (type === QUESTION_TYPE.MAP_MARKER) {
      const dataVisualizer: {
        questionId: string;
        questionLabel: string;
      } = {
        questionId: id,
        questionLabel: name
      };
      mapVisualizers.push(dataVisualizer);
    }
  }

  return mapVisualizers;
};

const mapRespondentAnswers = (
  respondent: { answers: any; meta: any },
  splitOptions: boolean
) => {
  const { answers } = respondent;

  let mappedAnswers: any = {};

  answers.forEach((a: any) => {
    const { answer, question } = a;
    const { type, id } = question;
    if (type === QUESTION_TYPE.INPUT) {
      mappedAnswers[id] = answer;
    } else if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN
    ) {
      const { options } = question;

      if (splitOptions) {
        mappedAnswers[`${id}___${answer}`] = "1";
      } else {
        const option = options.find((o: any) => o.id === answer);
        if (option) mappedAnswers[id] = option.label;
      }
    } else if (type === QUESTION_TYPE.RADIO_MULTI) {
      const { options } = question;

      answer.forEach((localA: string) => {
        const option = options.find((o: any) => o.id === localA);
        if (option) mappedAnswers[`${id}___${localA}`] = option.label;
      });

      // if (splitOptions) {
      //   answer.forEach((localA: string) => {
      //     mappedAnswers[`${id}___${localA}`] = "1";
      //   });
      // } else {
      //   let combinedAnswer = "";

      //   answer.forEach((localA: string, idx: number) => {
      //     combinedAnswer +=
      //       options.find((o: any) => o.id === localA).label +
      //       (idx < answer.length - 1 ? ", " : "");
      //   });

      //   mappedAnswers[id] = combinedAnswer;
      // }
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      const { conditionId, conditions } = question;
      const conditionAnswer = answers.find(
        (a: any) => a.question.id === conditionId
      );

      if (conditionAnswer) {
        const { answer: cA, question: cQuestion } = conditionAnswer;
        const conditionsAnswer = conditions.find((c: any) => c.id === cA);

        if (conditionsAnswer) {
          if (splitOptions) {
            mappedAnswers[
              `${cQuestion.id}___${cA}___${question.id}___${answer}`
            ] = "1";
          } else {
            const option = conditionsAnswer.options.find(
              (o: any) => o.id === a.answer
            );
            if (option) mappedAnswers[question.id] = option.label;
          }
        }
      }
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      const { conditionId, conditions } = question;
      const conditionAnswer = answers.find(
        (a: any) => a.question.id === conditionId
      );

      if (conditionAnswer) {
        const { answer: cA, question: cQuestion } = conditionAnswer;
        const conditionsAnswer = conditions.find((c: any) => c.id === cA);

        if (conditionsAnswer) {
          if (splitOptions) {
            cA.forEach((localA: string) => {
              const option = conditionsAnswer.options.find(
                (o: any) => o.id === localA
              );
              if (option)
                mappedAnswers[
                  `${cQuestion.id}___${cA}___${question.id}___${localA}`
                ] = "1";
            });
          } else {
            const option = conditionsAnswer.options.find(
              (o: any) => o.id === cA
            );
            if (option) mappedAnswers[`${id}___${cA}`] = option.label;
          }
        }
      }
    }
  });

  return mappedAnswers;
};

export const getQuestionResponseCountMap = (
  questionId: string,
  surveyRespones: { answers: any; meta: any }[]
) => {
  const answerCount: Map<string, number> = new Map();

  surveyRespones.forEach((surveyResponse: any, idx) => {
    const surveyAnswer = surveyResponse.answers.find(
      (a: any) => a.question.id === questionId
    );
    if (!surveyAnswer) return;
    const { answer, question } = surveyAnswer;
    const { type } = question;

    /** initialize the answer count map */
    if (idx === 0) {
      question.options.forEach((option: any) => {
        answerCount.set(option.label, 0);
      });
    }

    if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN
    ) {
      const { options } = question;

      const answerLabel = options.find((o: any) => o.id === answer).label;
      incrementMapValue(answerCount, answerLabel);
    } else if (type === QUESTION_TYPE.RADIO_MULTI) {
      const { options } = question;

      answer.forEach((localA: string) => {
        const answerLabel = options.find((o: any) => o.id === localA).label;
        incrementMapValue(answerCount, answerLabel);
      });
    }
  });

  return answerCount;
};

export const getConditionQuestionResponseCountMap = (props: {
  questionId: string;
  surveyRespones: { answers: any; meta: any }[];
  conditionQuestionId: string;
}) => {
  const { questionId, surveyRespones, conditionQuestionId } = props;

  const answerCount: Map<string, number> = new Map();

  surveyRespones.forEach((surveyResponse: any, idx) => {
    const surveyAnswer = surveyResponse.answers.find(
      (a: any) => a.question.id === questionId
    );
    const conditionSurveyAnswer = surveyResponse.answers.find(
      (a: any) => a.question.id === conditionQuestionId
    );
    if (!surveyAnswer || !conditionSurveyAnswer) return;
    const { answer, question } = surveyAnswer;

    const { type } = question;
    const appliedCondition = question.conditions.find(
      (c: any) => c.id === conditionSurveyAnswer.answer
    );

    /** initialize the answer count map */
    if (idx === 0) {
      const allConditionsOptions = question.conditions
        .map((c: any) => c.options)
        .flat();
      allConditionsOptions.forEach((option: any) => {
        answerCount.set(option.label, 0);
      });
    }

    if (!appliedCondition) return;

    if (
      type === QUESTION_TYPE.DROPDOWN_CONDITION ||
      type === QUESTION_TYPE.RADIO_CONDITION
    ) {
      const answerCondition = appliedCondition.options.find(
        (o: any) => o.id === answer
      );
      incrementMapValue(answerCount, answerCondition?.label);
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      const { options } = appliedCondition;

      answer.forEach((localA: string) => {
        const answerLabel = options.find((o: any) => o.id === localA).label;
        incrementMapValue(answerCount, answerLabel);
      });
    }
  });

  return answerCount;
};

const incrementMapValue = (map: Map<string, number>, key: string) => {
  const count = map.get(key) || 0;
  map.set(key, count + 1);
};

const sameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const mapMarkersToGeoJson = (mapMarkers: any) => {
  return {
    type: "FeatureCollection",
    features: mapMarkers.map((marker: any) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [marker.longitude, marker.latitude]
      },
      properties: {
        id: marker.id
      }
    }))
  };
};

const convertMarkersToGeoJsonExport = (mapMarkers: any) => {
  return {
    type: "FeatureCollection",
    features: mapMarkers.map((marker: any) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [marker.longitude, marker.latitude]
      },
      properties: {
        id: marker.id,
        respondentId: marker.respondentId
      }
    }))
  };
};

const extractAllSurveyQuestionsV2 = (
  survey: Survey,
  excludeTypes?: string[]
) => {
  const { pages } = survey;

  /** Extract all page questions in single array */
  const questions: (Question & { pageIdx: number })[] = [];
  const excludeQuestionTypes = excludeTypes || [];

  pages.forEach((page, pageIdx) => {
    page.questions.forEach((question) => {
      if (question.type === QUESTION_TYPE.MAP_MARKER) {
        const existingQuestion = questions.find(
          (existingQ) => existingQ.id === question.id
        );
        if (!existingQuestion) {
          questions.push({ ...question, pageIdx });
        }
      } else {
        if (!excludeQuestionTypes.includes(question.type)) {
          const existingQuestion = questions.find(
            (existingQ) => existingQ.id === question.id
          );
          if (!existingQuestion) {
            questions.push({ ...question, pageIdx });
          }
        }
      }
    });
  });
  return questions;
};

export {
  convertResponses as convertFireAnswers,
  convertFireAnswersToExport,
  mapSurveyQuestions,
  mapQuestionsToCsvMap,
  mapRespondentAnswers,
  getPossibleDataVisualizers,
  getPossibleDataVisualizersMap,
  getPossibleMapVisualizers,
  sameDay,
  mapMarkersToGeoJson,
  convertMarkersToGeoJsonExport,
  extractAllSurveyQuestionsV2
};
