import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Divider,
  List,
  ListItemButton,
  Collapse,
  ListItemText,
  DialogTitle
} from "@mui/material";
import {
  getPossibleDataVisualizersV2,
  getPossibleDataVisualizersMapV2
} from "../AnalyzePage.utils";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { GRAPH_TYPE } from "../DataVisualizer/DataVisualizer.types";
import { useCallback, useMemo, useState } from "react";
import GraphAddModalProps from "./GraphAddModal.types";

const GraphAddModal = (props: GraphAddModalProps) => {
  const { survey, onAdd, onClose, isOpen } = props;

  const [selectedGraphType, setSelectedGraphType] = useState<GRAPH_TYPE | null>(
    null
  );

  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  );

  const [expandedQCategory, setExpandedQCategory] = useState("");

  const [selectedVisualizer, setSelectedVisualizer] = useState<{
    questionId: string;
    questionLabel: string;
    visualisationTypes: GRAPH_TYPE[];
  }>();

  const possibleDataVisualizers = useMemo(() => {
    if (!survey) return;
    const visMap = getPossibleDataVisualizersMapV2(survey);

    return visMap;
  }, [survey]);

  const handleOnChangeSelectedGraphId = useCallback(
    (id: string, parentLabel: string) => {
      if (!possibleDataVisualizers) return;

      const selectedParentQuestions = possibleDataVisualizers.get(parentLabel);
      if (!selectedParentQuestions) return;
      const selectedDataVisualizer = selectedParentQuestions.find(
        (pdv) => pdv.questionId === id
      );
      setSelectedGraphType(
        selectedDataVisualizer?.visualisationTypes[0] ?? null
      );
      setSelectedVisualizer(selectedDataVisualizer);
    },
    [possibleDataVisualizers]
  );

  const handleOnClickAddGraph = useCallback(() => {
    if (!selectedGraphType || !selectedVisualizer || !onAdd) return;

    const dataVisualizer = {
      questionId: selectedVisualizer.questionId,
      questionLabel: selectedVisualizer.questionLabel,
      visualisationType: selectedGraphType,
      filters: []
    };
    onAdd(dataVisualizer);

    setSelectedQuestionId(null);
    setSelectedGraphType(null);
    setSelectedVisualizer(undefined);
  }, [onAdd, selectedGraphType, selectedVisualizer]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <DialogTitle>Lägg till graf</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: 1 }}>
          <List>
            {Array.from(possibleDataVisualizers?.entries() ?? []).map(
              ([questionCategory, questions]) => (
                <>
                  <ListItemButton
                    onClick={() => {
                      setExpandedQCategory(
                        expandedQCategory === questionCategory
                          ? ""
                          : questionCategory
                      );
                    }}
                  >
                    <ListItemText>
                      {questionCategory} ({questions.length})
                    </ListItemText>
                    {expandedQCategory === questionCategory ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Divider />
                  <Collapse
                    in={expandedQCategory === questionCategory}
                    timeout="auto"
                    unmountOnExit
                    sx={{ marginLeft: "24px" }}
                  >
                    {questions.map((q) => (
                      <ListItemButton
                        selected={selectedQuestionId === q.questionId}
                        onClick={() => {
                          setSelectedQuestionId(q.questionId);
                          handleOnChangeSelectedGraphId(
                            q.questionId,
                            questionCategory
                          );
                        }}
                        key={q.questionId}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "lightgray"
                          }
                        }}
                      >
                        {q.questionLabel}
                      </ListItemButton>
                    ))}
                  </Collapse>
                </>
              )
            )}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddGraph}>
          Lägg till graf
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GraphAddModal;
