import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Stack
} from "@mui/material";
import { getPossibleMapVisualizers } from "../AnalyzePage.utils";
import { useCallback, useMemo, useState } from "react";
import MapAddModalProps from "./MapAddModal.types";

const MapAddModal = (props: MapAddModalProps) => {
  const { survey, onAdd, onClose, isOpen } = props;

  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  );

  const possibleMapVisualizers = useMemo(() => {
    if (!survey) return [];
    const p = getPossibleMapVisualizers(survey);
    return p;
    /** Extract questions, their possible graph visualization types and the data for each visualisaiton */
  }, [survey]);

  const handleOnClickAddMap = useCallback(() => {
    if (possibleMapVisualizers.length === 0 || !selectedQuestionId || !onAdd)
      return;

    const selectedMapVisualizer = possibleMapVisualizers.find(
      (pdv) => pdv.questionId === selectedQuestionId
    );
    if (!selectedMapVisualizer) return;

    const mapVisualizer = {
      questionId: selectedQuestionId,
      questionLabel: selectedMapVisualizer.questionLabel
    };
    onAdd(mapVisualizer);

    setSelectedQuestionId(null);
  }, [onAdd, possibleMapVisualizers, selectedQuestionId]);

  return (
    <Dialog
      className="graph-add-modal"
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Lägg till karta</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: 1 }}>
          <List>
            {possibleMapVisualizers.map((x) => (
              <ListItemButton
                onClick={() => {
                  setSelectedQuestionId(x.questionId);
                }}
                key={x.questionId}
                selected={selectedQuestionId === x.questionId}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "lightgray"
                  }
                }}
              >
                <ListItemText>{x.questionLabel}</ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddMap}>
          Lägg till karta
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapAddModal;
