import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QUESTION_TYPE } from "enums";
import { useCallback, useMemo, useState } from "react";
import { FILTER_COMPARISON } from "../AnalyzePage.types";
import FilterModalProps from "./FilterModal.types";
import { FilterFire } from "interfaces";
import { nanoid } from "nanoid";
import {
  extractAllSurveyQuestions,
  getPossibleDataVisualizersMapV2,
  getPossibleDataVisualizersV2
} from "../AnalyzePage.utils";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NOT_SUPPORTED_QUESTION_TYPES = [
  QUESTION_TYPE.TEXT,
  QUESTION_TYPE.MAP_LINE,
  QUESTION_TYPE.MAP_MARKER,
  QUESTION_TYPE.INPUT
];

export const FILTER_COMPARISON_OPTIONS = [
  { value: FILTER_COMPARISON.IS, label: "Är" },
  { value: FILTER_COMPARISON.IS_NOT, label: "Är inte" },
  { value: FILTER_COMPARISON.CONTAINS, label: "Innehåller" }
];
const AppliedFilters = (props: {
  filters: FilterFire[];
  onRemoveFilter?: (filter: FilterFire) => void;
}) => {
  const { filters, onRemoveFilter } = props;

  const handleOnRemoveFilter = useCallback(
    (filter: FilterFire) => {
      if (!onRemoveFilter) return;
      onRemoveFilter(filter);
    },
    [onRemoveFilter]
  );

  if (filters.length === 0) return null;
  return (
    <Stack spacing={2}>
      <Typography fontSize={18} fontWeight={"bold"}>
        {"Applicerade filter:"}
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Typography fontSize={18} fontWeight={"bold"}>
            Fråga:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontSize={18} fontWeight={"bold"}>
            Jämförelse:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={18} fontWeight={"bold"}>
            Svar:
          </Typography>
        </Grid>
        {filters.map((f) => (
          <>
            <Grid item xs={4}>
              <Typography>{f.questionLabel}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {
                  FILTER_COMPARISON_OPTIONS.find(
                    (o) => o.value === f.filterComparison
                  )?.label
                }
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography> {f.filterValueLabel} </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={() => handleOnRemoveFilter(f)}>
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </>
        ))}
      </Grid>
    </Stack>
  );
};

const FilterModal = (props: FilterModalProps) => {
  const {
    isOpen,
    onClose,
    globalFilters,
    selectedVisualizer,
    onClickAddFilter,
    onRemoveFilter,
    survey
  } = props;

  const [selectedFilterValue, setSelectedFilterValue] = useState<string | null>(
    null
  );
  const [selectedFilterId, setSelectedFilterId] = useState<string | null>(null);
  const [selectedFilterComparison, setSelectedFilterComparison] =
    useState<FILTER_COMPARISON | null>(null);
  const [selectedFilterQuestionId, setSelectedFilterQuestionId] = useState<
    string | null
  >(null);
  const [expandedQCategory, setExpandedQCategory] = useState("");
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  );

  const filters = selectedVisualizer
    ? selectedVisualizer.filters
    : globalFilters;

  const questions = useMemo(() => {
    if (!survey) return [];
    const q = extractAllSurveyQuestions(survey, NOT_SUPPORTED_QUESTION_TYPES);
    q.sort((a, b) =>
      a.name.localeCompare(b.name, "sv", {
        sensitivity: "base"
      })
    );
    return q;
  }, [survey]);

  const selectedQuestion = useMemo(() => {
    if (!selectedFilterQuestionId || !survey) return;
    const questions = extractAllSurveyQuestions(survey);

    const newSelectedQuestion = questions.find(
      (q) => q.id === selectedFilterQuestionId
    );
    return newSelectedQuestion;
  }, [selectedFilterQuestionId, survey]);

  const selectedQuestionOptions = useMemo(() => {
    if (!selectedQuestion) return [];

    const { type } = selectedQuestion;
    if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN ||
      type === QUESTION_TYPE.RADIO_MULTI
    ) {
      return selectedQuestion.options
        .map((option) => ({
          label: option.label,
          value: option.id,
          name: option.id
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, "sv", {
            sensitivity: "base"
          })
        );
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      return selectedQuestion.conditions
        .map((option) => option.options)
        .flat()
        .map((option) => ({
          label: option.label,
          value: option.id,
          name: option.id
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, "sv", {
            sensitivity: "base"
          })
        );
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      return selectedQuestion.conditions
        .map((option) => option.options)
        .flat()
        .map((option) => ({
          label: option.label,
          value: option.id,
          name: option.id
        }))
        .sort();
    }
    return [];
  }, [selectedQuestion]);

  const resetStates = useCallback(() => {
    setSelectedFilterQuestionId(null);
    setSelectedFilterComparison(null);
    setSelectedFilterValue(null);
    setSelectedFilterId(null);
  }, []);

  const handleOnClickAddFilter = useCallback(() => {
    if (!onClickAddFilter) return;
    if (
      !selectedFilterQuestionId ||
      !selectedFilterComparison ||
      !selectedFilterValue ||
      !selectedQuestion
    )
      return;

    const filter = {
      id: nanoid(),
      questionId: selectedFilterQuestionId,
      filterComparison: selectedFilterComparison,
      filterValue: selectedFilterValue,
      filterValueLabel:
        selectedQuestionOptions.find((o) => o.value === selectedFilterId)
          ?.label ?? "",
      questionLabel: selectedQuestion.name
    };

    onClickAddFilter(filter);
    resetStates();
  }, [
    onClickAddFilter,
    resetStates,
    selectedFilterComparison,
    selectedFilterId,
    selectedFilterQuestionId,
    selectedFilterValue,
    selectedQuestion,
    selectedQuestionOptions
  ]);

  const handleOnClose = useCallback(() => {
    onClose();
    resetStates();
  }, [onClose, resetStates]);

  const possibleDataVisualizers = useMemo(() => {
    if (!survey) return;
    const p = getPossibleDataVisualizersV2(survey);
    const visMap = getPossibleDataVisualizersMapV2(survey);

    p.sort((a, b) =>
      a.questionLabel.localeCompare(b.questionLabel, "sv", {
        sensitivity: "base"
      })
    );
    return visMap;
  }, [survey]);

  const [openFilterSelect, setOpenFilterSelect] = useState(false);

  return (
    <Dialog
      className="filter-modal"
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {selectedVisualizer
          ? `Filter för fråga: ${
              questions.find((q) => q.id === selectedVisualizer.questionId)
                ?.name ?? "-"
            }`
          : "Globalt Filter"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: 1 }}>
          <AppliedFilters filters={filters} onRemoveFilter={onRemoveFilter} />

          {selectedFilterQuestionId ? (
            <Stack>
              <Typography fontWeight="Bold">Vald fråga</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  {
                    questions.find((d) => d.id === selectedFilterQuestionId)
                      ?.name
                  }
                </Typography>
                <Button
                  onClick={() => {
                    setSelectedFilterQuestionId(null);
                    setOpenFilterSelect(true);
                    setExpandedQCategory("");
                  }}
                >
                  Byt
                </Button>
              </Stack>
            </Stack>
          ) : (
            <>
              <FormControl fullWidth>
                <InputLabel>Välj fråga att filtrera på</InputLabel>
                <Select
                  placeholder="Välj fråga"
                  id="selectContainer"
                  value={selectedFilterQuestionId}
                  label={
                    questions.find((d) => d.id === selectedFilterQuestionId)
                      ?.name
                  }
                  sx={{ backgroundColor: "lightgray" }}
                  open={openFilterSelect}
                  onClick={(e) => {
                    const { target } = e;

                    if (target && "id" in target) {
                      if (target.id === "selectContainer") {
                        setOpenFilterSelect(true);
                      } else if (target.id === "selectChild") {
                        setOpenFilterSelect(false);
                      }
                    }
                  }}
                  onClose={() => {
                    setSelectedFilterQuestionId(null);
                    setOpenFilterSelect(false);
                    setExpandedQCategory("");
                  }}
                >
                  {Array.from(possibleDataVisualizers?.entries() ?? []).map(
                    ([questionCategory, questions]) => (
                      <>
                        <ListItemButton
                          onClick={() => {
                            setExpandedQCategory(
                              expandedQCategory === questionCategory
                                ? ""
                                : questionCategory
                            );
                          }}
                        >
                          <ListItemText>
                            {questionCategory} ({questions.length})
                          </ListItemText>
                          {expandedQCategory === questionCategory ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Divider />
                        <Collapse
                          in={expandedQCategory === questionCategory}
                          timeout="auto"
                          unmountOnExit
                          sx={{ marginLeft: "24px" }}
                        >
                          {questions.map((q) => (
                            <MenuItem
                              id="selectChild"
                              selected={selectedQuestionId === q.questionId}
                              onClick={() => {
                                setSelectedQuestionId(q.questionId);
                                setSelectedFilterQuestionId(q.questionId);
                              }}
                              key={q.questionId}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: "lightgray"
                                }
                              }}
                            >
                              {q.questionLabel}
                            </MenuItem>
                          ))}
                        </Collapse>
                      </>
                    )
                  )}
                </Select>
              </FormControl>
            </>
          )}

          {selectedFilterQuestionId && (
            <FormControl fullWidth>
              <InputLabel>{"Filter jämförelse"}</InputLabel>
              <Select
                value={selectedFilterComparison}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedFilterComparison(value as FILTER_COMPARISON);
                }}
                label={
                  FILTER_COMPARISON_OPTIONS.find(
                    (o) => o.value === selectedFilterComparison
                  )?.label
                }
                sx={{ backgroundColor: "lightgray" }}
              >
                {FILTER_COMPARISON_OPTIONS.map((x) => (
                  <MenuItem key={x.value} value={x.value}>
                    {x.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedQuestionOptions.length > 0 && (
            <FormControl fullWidth>
              <InputLabel>{"Värde"}</InputLabel>
              <Select
                value={selectedFilterId}
                onChange={(e) => {
                  const { value } = e.target;

                  setSelectedFilterId(value as string);

                  const selectedOption = selectedQuestionOptions.find(
                    (x) => x.value === value
                  )?.name;

                  if (selectedOption) setSelectedFilterValue(selectedOption);
                }}
                label={
                  selectedQuestionOptions.find(
                    (o) => o.value === selectedFilterValue
                  )?.label
                }
                sx={{ backgroundColor: "lightgray" }}
              >
                {selectedQuestionOptions.map((x) => (
                  <MenuItem key={x.value} value={x.value}>
                    {x.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleOnClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddFilter}>
          Lägg till filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
